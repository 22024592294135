<template>
  <div class="registered">
    <div class="registered-center">
      <!-- <span>注册</span> -->
    </div>
    <div class="logo">
      <img
        width="180px"
        src="../../assets/bitsun.png"
        alt=""
        class="Wallet"
      />
      <!-- <img :src="logoImg" alt="" class="Wallet" /> -->
    </div>
    <div class="form">
      <!-- <input v-if="isPhone" v-model="phone" type="tel" placeholder="输入手机号" /> -->
      <!-- <div class="phone" v-show="isPhone">
        <select v-model="country">
          <option value="1">11</option>
          <option value="2">2</option>
          <option value="3">33</option>
        </select>
        <input v-model="phone" type="tel" placeholder="输入手机号" />
      </div> -->
      <div class="phone" v-show="isPhone">
        <Select
          v-model="country"
          @change="changeSelect"
          style="width: 80px; height: 49px"
          size="large"
        >
          <Option
            v-for="item in countryList"
            :value="item.zhName"
            :key="item.enName"
            >{{ item.zhName }}</Option
          >
        </Select>
        <input v-model="phone" type="tel" placeholder="输入手机号" />
      </div>
      <input
        v-show="!isPhone"
        v-model="email"
        type="email"
        placeholder="输入邮箱账号"
      />
      <div class="phoneCode">
        <input
          class="phoneCodeInput"
          v-model="code"
          center
          clearable
          maxlength="6"
          placeholder="请输入验证码"
        />
        <button
          class="phoneCodeButton"
          size="small"
          type="primary"
          :disabled="!getCode"
          @click="countDown"
          :class="{ active: isActive }"
          :style="{ background: btnBackgroundColor, color: btnTextColor }"
        >
          <!-- <span v-if="!getCode">发送验证码</span> -->
          <!-- <span ref="contentRef" v-else>{{ content }}</span> -->
          <span ref="contentRef">{{ content }}</span>
        </button>
      </div>
      <input
        placeholder="输入邀请码"
        v-model="promotion"
        :disabled="isDisabled"
        v-if="showCode"
      />
      <input placeholder="输入密码" v-model="pass" type="password" />
      <input placeholder="请再次输入密码" v-model="rePass" type="password" />
      <button
        class="registeredButton"
        style="width: 100%"
        @click="registeredBefore"
        v-show="show_btn"
        :style="{ background: btnBackgroundColor, color: btnTextColor }"
      >
        立即注册
      </button>
      <div style="width: 100%; display: flex; justify-content: space-between">
        <div
          class="toggle"
          :style="{ color: btnBackgroundColor }"
          @click="onToggle"
          v-if="showPhone"
        >
          {{ !isPhone ? "使用手机号" : "使用邮箱" }}
        </div>
        <div
          class="toggle"
          :style="{ color: btnBackgroundColor }"
          @click="download"
          v-if="showDown"
        >
          下载APK
        </div>
      </div>
      <!-- <button
          class="donwloadButton"
          style="width: 100%"
          @click="download"
          v-show="show_btn">
          跳转官网
        </button> -->
      <!-- <div class="all_copy">
        <div class="Input_copy">{{ copyText }}</div>
        <button class="copy_btn" @click="copyTexts">复制链接</button>
      </div> -->
    </div>
  </div>
</template>

<script>
import "../../assets/css/style.less";
import { getUrlKey } from "../../utils/getUrlKey";
import bisheng from "../../assets/bisheng-logo.png";
import bitBankHK from "../../assets/bit-bank-hk-logo.png";
import bitBank from "../../assets/bit-bank-logo.png";
import coinGN from "../../assets/coinGN-logo.png";
import easyTransfer from "../../assets/easyTransfer-logo.png";
import foxex from "../../assets/foxex-logo.png";
import goko from "../../assets/goko-logo.png";
import spx from "../../assets/spx-logo.png";
import bwhale from "../../assets/bwhale-logo.png";
import ningmengweb from "../../assets/ningmengweb-logo.png";
import cyberchain from "../../assets/cyberchain-logo.png";
import tiansheng from "../../assets/tiansheng-logo.png";
import coinPor from "../../assets/coinpor-logo.png";
export default {
  data() {
    return {
      isPhone: false,
      // copyText: "https://app.librearth.com/LEX.apk", //下载地址
      country: "中国", // 国家
      countryList: [],
      //邀请码禁止输入
      isDisabled: true,
      // 发送验证码按钮颜色
      isActive: false,
      background: "",
      // 获取验证码
      getCode: true,
      //改变发送验证码的颜色
      show_btn: true,
      email: "",
      // 手机号
      phone: "",
      // 验证码
      code: "",
      screenHeight: "",
      // inviteCode: "",
      pass: "",
      rePass: "",
      cleanInviteCode: false,
      // 用户名判断
      accountNameYes: 1,
      // 手机号判断
      phoneYes: 1,
      // 密码判断
      pwdYes: 1,
      // 支付密码判断
      payYes: 1,
      // 验证码判断
      phoneCodeYes: 1,
      // 是否可以注册
      goReg: false,
      packCode: "", //红包码
      promotion: "", //邀请码
      content: "获取验证码", // 按钮里显示的内容
      totalTime: 60, //记录具体倒计时时间
      // canClick: true, //添加canClick解决多次点击之后，倒讲时速度变快
      // 域名对应关系
      os:"Android",
      logoDic: {
        "bsex.pro": {
          logo: bisheng,
          background: "#fedf2d",
          color: "#222",
          showPhone: true,
          afterSuccessToApk: false,
          showCode: true,
          showDown: false,
          downloadApkUrl: "/download/1.0.0.apk",
        },
        "krlsite.net": {
          logo: bisheng,
          background: "#4cd964",
          color: "#fff",
          showPhone: true,
          afterSuccessToApk: false,
          showCode: true,
          showDown: false,
          downloadApkUrl: "/download/1.0.0.apk",
        },
        "ismart.ltd": {
          logo: bisheng,
          background: "#4cd964",
          color: "#fff",
          showPhone: true,
          afterSuccessToApk: false,
          showCode: true,
          showDown: false,
          downloadApkUrl: "/download/1.0.0.apk",
        },
        "bitbank.exchange": {
          logo: bitBankHK,
          background: "#4cd964",
          color: "#fff",
          showPhone: true,
          afterSuccessToApk: false,
          showCode: true,
          showDown: false,
          downloadApkUrl: "/download/1.0.0.apk",
        },
        "bitbank.world": {
          logo: bitBank,
          background: "#4cd964",
          color: "#fff",
          showPhone: true,
          afterSuccessToApk: false,
          showCode: true,
          showDown: false,
          downloadApkUrl: "/download/1.0.0.apk",
        },
        "coingn.com": {
          logo: coinGN,
          background: "#4cd964",
          color: "#fff",
          showPhone: true,
          afterSuccessToApk: false,
          showCode: true,
          showDown: false,
          downloadApkUrl: "/download/1.0.0.apk",
        },
        "eazytransfer.org": {
          logo: easyTransfer,
          background: "#4cd964",
          color: "#fff",
          showPhone: true,
          afterSuccessToApk: false,
          showCode: true,
          showDown: false,
          downloadApkUrl: "/download/1.0.0.apk",
        },
        "foxex.cc": {
          logo: foxex,
          background: "#4cd964",
          color: "#fff",
          showPhone: false,
          afterSuccessToApk: false,
          showCode: true,
          showDown: false,
          downloadApkUrl: "/download/1.0.0.apk",
        },
        "rayss.club": {
          logo: goko,
          background: "#4cd964",
          color: "#fff",
          showPhone: false,
          afterSuccessToApk: true,
          showCode: false,
          showDown: false,
          downloadApkUrl: "/download/1.0.0.apk",
        },
        "spx.pics": {
          logo: spx,
          background: "#4cd964",
          color: "#fff",
          showPhone: true,
          afterSuccessToApk: false,
          showCode: true,
          showDown: false,
          downloadApkUrl: "/download/1.0.0.apk",
        },
        "bwhale.ltd": {
          logo: bwhale,
          background: "#4cd964",
          color: "#fff",
          showPhone: true,
          afterSuccessToApk: false,
          showCode: true,
          showDown: false,
          downloadApkUrl: "/download/1.0.0.apk",
        },
        "ningmengweb.com": {
          logo: ningmengweb,
          background: "#4cd964",
          color: "#fff",
          showPhone: false,
          afterSuccessToApk: false,
          showCode: true,
          showDown: false,
          downloadApkUrl: "/download/1.0.0.apk",
        },
        "cyberchain.hk": {
          logo: cyberchain,
          background: "#4cd964",
          color: "#fff",
          showPhone: true,
          afterSuccessToApk: true,
          showCode: true,
          showDown: false,
          downloadApkUrl: "/download/1.0.0.apk",
        },
        "skysp.info": {
          logo: tiansheng,
          background: "#4cd964",
          color: "#fff",
          showPhone: true,
          afterSuccessToApk: true,
          showCode: true,
          showDown: false,
          downloadApkUrl: "/download/1.0.0.apk",
        },
        "coinpro.world": {
          logo: coinPor,
          background: "#4cd964",
          color: "#fff",
          showPhone: true,
          afterSuccessToApk: true,
          showCode: true,
          showDown: true,
          downRoute: "/CoinProDown",
          downloadApkUrl: "/download/CoinPro.apk",
        },
        "dmzhysg.info": {
          logo: tiansheng,
          background: "#4cd964",
          color: "#fff",
          showPhone: true,
          afterSuccessToApk: true,
          showCode: true,
          showDown: false,
          downRoute: "/CoinProDown",
          downloadApkUrl: "http://api.dmzhysg.info/upload/dmz.apk",
        },
      },
    };
  },
  computed: {
    logoImg() {
      try {
        const host = window.location.host;
        const key = host.substring(host.indexOf(".") + 1);
        return this.logoDic[key].logo;
      } catch (error) {
        return bisheng;
      }
    },
    btnTextColor() {
      try {
        const host = window.location.host;
        const key = host.substring(host.indexOf(".") + 1);
        return this.logoDic[key].color;
      } catch (error) {
        return "#222";
      }
    },
    btnBackgroundColor() {
      try {
        const host = window.location.host;
        const key = host.substring(host.indexOf(".") + 1);
        return this.logoDic[key].background;
      } catch (error) {
        return "#fedf2d";
      }
    },
    showPhone() {
      try {
        const host = window.location.host;
        const key = host.substring(host.indexOf(".") + 1);
        return this.logoDic[key].showPhone;
      } catch (error) {
        return true;
      }
    },
    afterSuccessToApk() {
      try {
        const host = window.location.host;
        const key = host.substring(host.indexOf(".") + 1);
        return this.logoDic[key].afterSuccessToApk;
      } catch (error) {
        return false;
      }
    },
    showCode() {
      try {
        const host = window.location.host;
        const key = host.substring(host.indexOf(".") + 1);
        return this.logoDic[key].showCode;
      } catch (error) {
        return true;
      }
    },
    showDown() {
      try {
        const host = window.location.host;
        const key = host.substring(host.indexOf(".") + 1);
        return this.logoDic[key].showDown;
      } catch (error) {
        return false;
      }
    },
    downRoute() {
      try {
        const host = window.location.host;
        const key = host.substring(host.indexOf(".") + 1);
        return this.logoDic[key].downRoute;
      } catch (error) {
        return "/";
      }
    },
    downloadApkUrl() {
      const host = window.location.host;
      const key = host.substring(host.indexOf(".") + 1);
      if (this.logoDic[key].downloadApkUrl.indexOf("http") > -1) {
        return this.logoDic[key].downloadApkUrl;
      }
      const hosts = window.location.host.split(".");
      hosts[0] = "admin";
      return "http://" + hosts.join(".") + this.logoDic[key].downloadApkUrl;
    },
  },
  created() {
    // if (this.$route.query.inviteCode) {
    //   this.inviteCode = this.$route.query.inviteCode;
    // }
  },
  mounted() {
    this.os = this.detectOS();
    this.getCountryList();
    this.screenHeight = document.documentElement.clientHeight;
    let winh = window.innerHeight;
    let that = this;
    window.onresize = function () {
      let curh = window.innerHeight;
      if (winh - curh > 150) {
        that.show_btn = true;
      } else {
        that.show_btn = true;
      }
    };
    var messageData = location.hash;
    let code = getUrlKey("code");
    let pack_code = getUrlKey("pack_code");
    this.promotion = code;
    this.packCode = pack_code;
  },
  methods: {
    GetQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
      var context = "";
      if (r != null) context = decodeURIComponent(r[2]);
      reg = null;
      r = null;
      return context == null || context == "" || context == "undefined"
        ? ""
        : context;
    },
    getCountryList() {
      this.$hkshareApi
        .getCountry()
        .then((res) => (this.countryList = res.data));
    },
    changeSelect(e) {
      // console.log(e)
      this.country = e;
    },
    // 验证input 是否为空 true是有空值
    allInput() {
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
      // console.log(reg_tel.test(this.phone));
      // if (reg_tel.test(this.phone)) {
      //   if (
      //     this.phone.replace(/(^\s*)|(\s*$)/g, "") != "" &&
      //     this.code.replace(/(^\s*)|(\s*$)/g, "") != "" &&
      //     this.pass.replace(/(^\s*)|(\s*$)/g, "") != "" &&
      //     this.rePass.replace(/(^\s*)|(\s*$)/g, "") != ""
      //   ) {
      //     this.goReg = false;
      //   } else {
      //     this.$toast('请填写短信验证码以及密码')
      //     this.goReg = true;
      //   }
      // } else {
      //   this.$toast('请填写正确的手机号')
      //   this.goReg = true;
      // }
    },
    // 验证是否注册过
    async registeredBefore() {
      this.allInput();
      if (!this.goReg) {
        if (this.pass === this.rePass) {
          this.registered();
        } else {
          this.$toast("两次密码输入不一致, 请重新输入");
        }
      } else {
      }
    },
    // 注册
    async registered() {
      if (
        (this.phone != "" || this.email != "") &&
        this.pass != "" &&
        this.code != ""
      ) {
        let params = {
          password: this.pass,
          country: this.country,
          code: this.code,
          promotion: this.promotion,
          packCode: this.packCode,
        };
        if (this.isPhone) {
          params = {
            ...params,
            phone: this.phone,
            username: this.phone,
          };
        } else {
          params = {
            ...params,
            email: this.email,
            username: this.email,
          };
        }
        const req = this.isPhone
          ? await this.$hkshareApi.regPhone(params)
          : await this.$hkshareApi.regWithPass(params);
        console.log(req,'6666666');
        if (req.code == 0) {
          if(this.os == 'Android'){
             const res = await this.$hkshareApi.getAppVersion(0);
          this.$toast.success("注册成功");
            // this.download(res.data.downloadUrl);
            window.location.href = res.data.downloadUrl;
          }else if(this.os == 'iOS'){
            const res = await this.$hkshareApi.getAppVersion(1);
          this.$toast.success("注册成功");
          window.location.href = res.data.downloadUrl;
            // this.download(res.data.downloadUrl);
          }
          // const res = await this.$hkshareApi.getAppVersion(0);
          // this.$toast.success("注册成功");
          // // setTimeout(() => {
          //   this.download(res.data.downloadUrl);
          // }, 3000);
          // if (this.afterSuccessToApk) {
          // window.location.href = res.data.downloadUrl;
          // }
        } else {
          this.$toast.fail(req.message);
        }
      } else {
        this.$toast.fail("请核对页面参数");
      }
    },
    // 获取验证码
    async countDown() {
      if (this.phone != "" || this.email) {
        // if (!this.canClick) return; //改动的是这两行代码
        // this.canClick = false;
        const res = this.isPhone
          ? await this.$hkshareApi.sendPhoneCode({
              phone: this.phone,
              country: this.country,
            })
          : await this.$hkshareApi.sendAccountCode({ email: this.email });

        if (res.code == 0) {
          // this.$toast.success(res.data);
          this.$toast.success("验证码发送成功!");
        } else {
          this.$toast.fail(res.message);
          return;
        }
        this.isActive = true;
        let clock = window.setInterval(() => {
          this.totalTime--;
          this.$refs.contentRef.style.color = "#222";
          this.content = this.totalTime + "s";
          this.getCode = false;
          // console.log(this.totalTime);
          if (this.totalTime < 0) {
            //当倒计时小于0时清除定时器
            window.clearInterval(clock);
            this.$refs.contentRef.style.color = this.btnTextColor;
            this.isActive = false;
            this.getCode = true;
            this.content = "获取验证码";
            this.totalTime = 60;
            // this.canClick = true; //这里重新开启
          }
        }, 1000);
      } else {
        this.$toast.fail(`请输入正确${this.isPhone ? "手机号" : "邮箱号"}`);
      }
    },
    // 直接跳转下载
    download(data) {
      // var ua = window.navigator.userAgent.toLowerCase();
      // //非微信浏览器
      // if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
      //   // this.$toast.fail('ios暂时还没有下载地址')
      //   window.location = "http://www.xingyuanshuzi.cn/"; //ios下载地址
      // } else {
      //   // this.$toast.fail('安卓暂时还没有下载地址')
      //   window.location = "http://www.xingyuanshuzi.cn/"; //android下载地址
      // }
      const blob = new Blob([data], {
        type: "application/vnd.android.package-archive",
      });
      // 创建一个指向该Blob的URL
      const url = URL.createObjectURL(blob);
      // 创建一个临时的a标签
      const a = document.createElement("a");
      a.href = url;
      a.download = data.split('/').pop(); // 设置下载文件名
      // 触发下载
      document.body.appendChild(a);
      a.click();
      // 清理
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      // sessionStorage.setItem("downUrl", this.downloadApkUrl);
      // this.$router.push({ path: this.downRoute });
    },
    // 手机号失焦判断
    phoneblur() {
      let reg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/;
      if (!reg.test(this.phone)) {
        this.phoneYes = 1;
        this.goReg = true;
      } else {
        this.allInput();
      }
    },
    // 手机邮箱切换
    onToggle() {
      this.isPhone = !this.isPhone;
      this.isPhone ? (this.email = "") : (this.phone = "");
    },
        // 检测机型
        detectOS() {
      const userAgent = navigator.userAgent;

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return 'iOS';
      } else if (/Android/.test(userAgent)) {
          return 'Android';
      } else {
          return 'Other';
      }
    }

    // 复制功能
    // copyTexts() {
    //   // navigator clipboard 需要https等安全上下文
    //   if (navigator.clipboard && window.isSecureContext) {
    //     // navigator clipboard 向剪贴板写文本
    //     // return navigator.clipboard.writeText(this.copyText);
    //     navigator.clipboard
    //       .writeText(this.copyText)
    //       .then(() => {
    //         this.$toast.success("文本已复制到剪贴板");
    //       })
    //       .catch((err) => {
    //         // 复制失败的处理逻辑
    //         console.error("无法复制文本: ", err);
    //         this.$toast.fail(`无法复制文本: ${err}`);
    //       });
    //     //  },
    //   } else {
    //     // 创建text area
    //     let textArea = document.createElement("textarea");
    //     textArea.value = this.copyText;
    //     // 使text area不在viewport，同时设置不可见
    //     // textArea.style.position = "absolute";
    //     // textArea.style.opacity = 0;
    //     // textArea.style.left = "-999999px";
    //     // textArea.style.top = "-999999px";
    //     document.body.appendChild(textArea);
    //     textArea.focus();
    //     textArea.select();
    //     return new Promise((res, rej) => {
    //       // 执行复制命令并移除文本框
    //       document.execCommand("copy") ? this.$toast.success("文本已复制到剪贴板") : this.$toast.fail(`无法复制文本: ${err}`);
    //       textArea.remove();
    //     });
    //   }
    // },
  },
};
</script>

<style scoped="scoped">
.logo {
  margin: 20px 0 !important;
}

.active {
  background: #f5f5f5 !important;
}

body {
  position: relative;
  height: 100%;
  width: 100%;
  background: #ffffff;
  margin: 0 auto;
}

.phone {
  display: flex;
  align-items: center;
  width: 100%;
}

/* /deep/ .ivu-select-large.ivu-select-single .ivu-select-selection .ivu-select-placeholder,
.ivu-select-large.ivu-select-single .ivu-select-selection .ivu-select-selected-value {
  height: 49px !important;
  line-height: 49px !important;
} */

::v-deep .ivu-select-large.ivu-select-single .ivu-select-selection {
  width: 100%;
  height: 49px !important;
  display: flex;
  align-items: center;
  background: #f5f5f5 !important;
  border: 0px solid #f5f5f5 !important;
  border-radius: 0 !important;
}

.registeredButton {
  color: #222222;
  font-size: 16px;
  border-radius: 6px;
  background: #fedf2d;
  font-weight: bold;
}

.registered-center {
  padding: 14px 175px 13px 176px;
  text-align: center;
  font-size: 12px;
  width: 375px;
  height: 44px;
  /* display: flex;
  justify-content: center; */
}

.Wallet {
  width: 80pt;
  /* height: 134pt; */
}

.registered {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-size: 100% 100%;
  background-position: center;
  background: #ffffff;
}

.form input {
  background: #f5f5f5;
  border: 0px solid #f5f5f5;
  border-radius: 6px;
}

.phoneCodeButton {
  color: #222222;
  font-size: 15px;
  border-radius: 6px;
  background: #fedf2d;
  /* padding: 16px 20px; */
}

.tip {
  margin-top: 5%;
  font-size: 12px;
  color: #919499;
}

.tip span {
  font-size: 12px;
  color: #4ea5dc;
}

/* input::-webkit-input-placeholder {
  font-size: 14px;
  color: rgb(145, 145, 145);
} */

.toggle {
  /* width: 100%; */
  /* text-align: left; */
  padding: 10px;
  /* display: flex; */
}
.all_copy {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.Input_copy {
  width: 241px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #737989;
}
.copy_btn {
  width: 84px;
  height: 28px;
  background: #fedf2d;
  border-radius: 2px;
  margin-left: 13px;
}
</style>
